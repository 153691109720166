import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Layout from "./Layout";
import { formatDateWithOrdinal } from "../Helpers/DateHelpers";

function UserAuditLogTable() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Correctly use useCallback for fetchAuditLogs
  const fetchAuditLogs = useCallback(async () => {
    const params = { search, startDate, endDate, page };
    const response = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/activity`,
      {
        withCredentials: true,
        params: params,
      }
    );
    setAuditLogs(response.data.data);
    setTotalPages(response.data.totalPages);
  }, [search, startDate, endDate, page]);

  useEffect(() => {
    fetchAuditLogs();
  }, [fetchAuditLogs]);

  const handleSearch = () => {
    setPage(1); 
  };

  return (
    <Layout>
      <div className="container">
        <div className="section">
          <h3 className="title is-4">My activity</h3>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div>
                  <label className="label">Search events</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Search events..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">Start date</label>
                  <input
                    className="input"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">End date</label>
                  <input
                    className="input"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">&nbsp;</label>
                  <button className="button is-info" onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>Date</th>
                <th>User Email</th>
                <th>Event</th>
                <th>Client IP</th>
              </tr>
            </thead>
            <tbody>
              {auditLogs.map((log) => (
                <tr key={log._id}>
                  <td>{formatDateWithOrdinal(log.timestamp)}</td>
                  <td>{log.userEmail}</td>
                  <td>{log.event}</td>
                  <td>{log.clientIp}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className="pagination" role="navigation" aria-label="pagination">
            <button
              className="button is-info"
              onClick={() => setPage(page - 1)}
              disabled={page <= 1}
            >
              Previous
            </button>
            <button
              className="button is-info"
              onClick={() => setPage(page + 1)}
              disabled={page >= totalPages}
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </Layout>
  );
}

export default UserAuditLogTable;