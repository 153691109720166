import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import SearchResults from "./SearchResults";
import { toast } from "react-toastify";

const SearchPage = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [returnFirstMatch, setReturnFirstMatch] = useState(false);
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchResponse, setSearchResponse] = useState({ tickets: [] });
  const [searchTypes, setSearchTypes] = useState({
    identifier: true,
    barcode: true,
    plate: true,
  });

  const navigate = useNavigate();

  // Sanitize and limit query input
  const sanitizeInput = (input) => {
    const sanitized = input.replace(/[^a-zA-Z0-9\s]/g, "");
    return sanitized.slice(0, 24);
  };

  const handleSearchTypeChange = (type) => {
    setSearchTypes((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleSearch = async () => {
    const sanitizedQuery = sanitizeInput(query);

    if (sanitizedQuery.length < 3) {
      setErrorMessage(
        "Enter at least 3 characters of a Customer ID, Plate Number, or Barcode. Enter the full ticket ID for the best performance."
      );
      return;
    }

    if (!searchTypes.identifier && !searchTypes.barcode && !searchTypes.plate) {
      setErrorMessage(
        "Please select at least one search type (Identifier, Barcode, or Plate Number)."
      );
      return;
    }

    setLoading(true);
    setSearchAttempted(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}/search`,
        {
          searchString: sanitizedQuery,
          searchTypes: searchTypes,
        },
        {
          withCredentials: true,
          headers: { Accept: "application/json" },
        }
      );

      console.log("Full search response:", response.data);
      console.log("Tickets in response:", response.data.tickets);

      const { tickets } = response.data;

      if (returnFirstMatch && tickets && tickets.length === 1) {
        console.log(
          "Going to first match:",
          `/ticket/${tickets[0].siteId}/${tickets[0].identifier}`
        );
        navigate(`/ticket/${tickets[0].siteId}/${tickets[0].identifier}`);
      } else {
        setSearchResponse({ tickets });
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setErrorMessage(
        "Failed to fetch search results. " +
          (error.response?.data?.error || error.message) +
          ". Please try again or contact support."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          <div className="field">
            <div className="control">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <label className="label">Search by:</label>
                  </div>
                  <div className="level-item">
                    <div className="buttons has-addons">
                      <button
                        className={`button ${
                          searchTypes.identifier ? "is-info is-selected" : ""
                        }`}
                        onClick={() => handleSearchTypeChange("identifier")}
                      >
                        {searchTypes.identifier ? "👍" : "⛔"} Identifier
                      </button>
                      <button
                        className={`button ml-1 mr-1 ${
                          searchTypes.barcode ? "is-info is-selected" : ""
                        }`}
                        onClick={() => handleSearchTypeChange("barcode")}
                      >
                        {searchTypes.barcode ? "👍" : "⛔"} Barcode
                      </button>
                      <button
                        className={`button ${
                          searchTypes.plate ? "is-info is-selected" : ""
                        }`}
                        onClick={() => handleSearchTypeChange("plate")}
                      >
                        {searchTypes.plate ? "👍" : "⛔"} Plate Number
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field has-addons" style={{ justifyContent: "center" }}>
            <div className="control is-expanded">
              <input
                className="input is-large"
                type="text"
                placeholder="Search by ticket identifier, barcode or plate number..."
                value={query}
                onChange={(e) => setQuery(sanitizeInput(e.target.value))}
                onKeyDown={handleKeyDown}
                autoFocus
                maxLength="24"
              />
            </div>
            <div className="control">
              <button
                className={`button is-info is-large ${loading ? "is-loading" : ""}`}
                style={{ width: "150px" }}
                onClick={handleSearch}
              >
                {loading ? "" : "Search"}
              </button>
            </div>
          </div>

          {errorMessage && (
            <p className="notification is-danger">{errorMessage}</p>
          )}

          {!loading && searchAttempted && searchResponse.tickets && searchResponse.tickets.length > 0 ? (
            <SearchResults searchResponse={searchResponse} navigate={navigate} />
          ) : (
            searchAttempted &&
            !loading && (
              <p className="notification is-danger">
                No results found that match your search criteria. Ensure you have access to at least 1 site and try again.
              </p>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchPage;
