import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Layout from "./Layout";
import Select from 'react-select';
import { formatDateWithOrdinal } from "../Helpers/DateHelpers";

// Function to sanitize text input by removing special characters
const sanitizeText = (text) => {
  return text.replace(/[^a-zA-Z0-9\s]/g, "");
};

// Function to validate date inputs
const validateDate = (date) => {
  return date && !isNaN(new Date(date).getTime()) ? date : "";
};

function AuditLogTable() {
  const [auditLogs, setAuditLogs] = useState([]);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [showBeforeAfter, setShowBeforeAfter] = useState(true); // Show before/after by default
  const [showChanges, setShowChanges] = useState(true); // Hide changes by default

  useEffect(() => {
    // Fetch users when component mounts
    const fetchUsers = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/users`, { withCredentials: true });
      const userOptions = response.data.map(user => ({ value: user._id, label: user.email }));
      setUsers(userOptions);
    };

    fetchUsers();
  }, []);

  const fetchAuditLogs = useCallback(async () => {
    const userIds = selectedUsers.map(user => user.value);
    const params = { 
      search: sanitizeText(search), 
      userIds, 
      startDate: validateDate(startDate), 
      endDate: validateDate(endDate), 
      page 
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}/audit-events`,
      {
        withCredentials: true,
        params: params,
      }
    );
    setAuditLogs(response.data.data);
    setTotalPages(response.data.totalPages);
  }, [search, selectedUsers, startDate, endDate, page]);

  const handleUserSelectChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
    setPage(1); // Reset page to 1 whenever user selections change
  };

  const handleSearch = () => {
    setPage(1);
    setTriggerSearch(prev => !prev); // Toggle to trigger useEffect
  };
  
  useEffect(() => {
    fetchAuditLogs();
  }, [fetchAuditLogs, triggerSearch]); 

  const getChangedProperties = (before = {}, after = {}) => {
    const changes = {};
    for (const key in after) {
      if (before[key] !== after[key]) {
        changes[key] = { before: before[key], after: after[key] };
      }
    }
    return changes;
  };

  const toggleBeforeAfterVisibility = () => {
    setShowBeforeAfter(prev => !prev);
  };

  const toggleChangesVisibility = () => {
    setShowChanges(prev => !prev);
  };

  return (
    <Layout>
      <style>
        {`
          .scrollable-pre {
            white-space: pre-wrap; /* Allows the text to wrap, can use pre if you prefer no wrapping */
            overflow-x: auto; /* Adds horizontal scroll */
            max-width: 300px; /* Adjust this width based on your layout */
            font-size: 10px; /* Ensure the text is small enough to fit */
          }
        `}
      </style>
      <div className="container">
        <div className="section">
          <h3 className="title is-4">Logs</h3>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div>
                  <label className="label">Search events</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Search events..."
                    value={search}
                    onChange={(e) => setSearch(sanitizeText(e.target.value))}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">Filter user(s)</label>
                    <Select
                        isMulti
                        name="users"
                        options={users}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleUserSelectChange}
                        value={selectedUsers}
                        placeholder="Select Users..."
                    />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">Start date</label>
                  <input
                    className="input"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(validateDate(e.target.value))}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">End date</label>
                  <input
                    className="input"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(validateDate(e.target.value))}
                  />
                </div>
              </div>

              <div className="level-item">
                <div>
                  <label className="label">&nbsp;</label>
                  <button className="button is-info" onClick={handleSearch}>
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={showBeforeAfter}
                    onChange={toggleBeforeAfterVisibility}
                  />
                  &nbsp; Show Before/After
                </label>
              </div>
              <div className="level-item">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={showChanges}
                    onChange={toggleChangesVisibility}
                  />
                  &nbsp; Show Changes
                </label>
              </div>
            </div>
          </div>

          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>Metadata</th>
                {showBeforeAfter && <th>Entity before</th>}
                {showBeforeAfter && <th>Entity after</th>}
                {showChanges && <th>Changes</th>}
              </tr>
            </thead>
            <tbody>
              {auditLogs.map((log) => {
                const changes = getChangedProperties(log.before || {}, log.after || {});
                return (
                  <tr key={log._id}>
                    <td>
                      <strong>{formatDateWithOrdinal(log.timestamp)} </strong><br/>
                      <strong>Actor:</strong> {log.userEmail} <br/>
                      <strong>Event:</strong> {log.event}
                    </td>
                    {showBeforeAfter && (
                      <td>
                        <pre className="scrollable-pre">
                          {JSON.stringify(log.before, null, 2)}
                        </pre>
                      </td>
                    )}
                    {showBeforeAfter && (
                      <td>
                        <pre className="scrollable-pre">
                          {JSON.stringify(log.after, null, 2)}
                        </pre>
                      </td>
                    )}
                    {showChanges && (
                      <td>
                        <pre className="scrollable-pre">
                          {JSON.stringify(changes, null, 2)}
                        </pre>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <nav className="pagination" role="navigation" aria-label="pagination">
            <button
              className="button is-info"
              onClick={() => setPage(page - 1)}
              disabled={page <= 1}
            >
              Previous
            </button>
            <button
              className="button is-info"
              onClick={() => setPage(page + 1)}
              disabled={page >= totalPages}
            >
              Next
            </button>
          </nav>
        </div>
      </div>
    </Layout>
  );
}

export default AuditLogTable;