import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import { formatDateWithOrdinal } from "../Helpers/DateHelpers";

function SearchHistory() {
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    const fetchSearchHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/history`,
          {
            withCredentials: true,
          }
        );
        console.log(response.data);
        setSearchHistory(response.data);
      } catch (error) {
        console.error("Error fetching search history:", error);
        // Handle error appropriately
      }
    };

    fetchSearchHistory();
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="section">
          <h3 className="title is-4">My search history</h3>
          <div className="table-container">
            <table className="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th>Search Term</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {searchHistory.map((item, index) => (
                  <tr key={index}>
                    <td>{item.searchTerm}</td>
                    <td>{formatDateWithOrdinal(item.timestamp)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SearchHistory;