import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";

const SitesPage = () => {
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isAddModalActive, setIsAddModalActive] = useState(false);
  const [isDeleteSiteModalOpen, setIsDeleteSiteModalOpen] = useState(false);
  const [currentSiteToDelete, setCurrentSiteToDelete] = useState(null);
  const [newSite, setNewSite] = useState({
    name: "",
    jms_server_address: "",
    jms_server_port: "",
    jms_server_keys: []
  });
  const [newKey, setNewKey] = useState({
    name: "Primary Key",
    key: "",
    isEnabled: true
  });

  // Define fetchSites function outside of useEffect
  const fetchSites = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_ADDRESS}/sites`, {
        withCredentials: true
      });
      setSites(response.data.sites);
    } catch (err) {
      console.error("Failed to fetch sites:", err);
      setError("Failed to load sites. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Call fetchSites within useEffect
    fetchSites();
  }, []);

  const handleSiteChange = (e) => {
    const { name, value } = e.target;
    setNewSite(prev => ({ ...prev, [name]: value }));
  };

  const handleKeyChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewKey(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Add the key to the site if it has value
    const siteToSubmit = { ...newSite };
    if (newKey.key.trim() !== "") {
      siteToSubmit.jms_server_keys = [
        {
          ...newKey,
          createdAt: new Date(),
          updatedAt: new Date()
        }
      ];
    }
    
    try {
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}/sites`, siteToSubmit, {
        withCredentials: true
      });
      setIsAddModalActive(false);
      // Reset form
      setNewSite({
        name: "",
        jms_server_address: "",
        jms_server_port: "",
        jms_server_keys: []
      });
      setNewKey({
        name: "Primary Key",
        key: "",
        isEnabled: true
      });
      toast.success("Site added successfully");
      fetchSites(); 
    } catch (err) {
      console.error("Failed to add site:", err);
      toast.error("Failed to add site. ", err.message);
    }
  };

  const handleDeleteSiteClick = (site) => {
    setCurrentSiteToDelete(site);
    setIsDeleteSiteModalOpen(true);
  };  

  const handleDeleteSite = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_ADDRESS}/sites/${currentSiteToDelete._id}`, {
        withCredentials: true
      });
      setIsDeleteSiteModalOpen(false); // Close the modal
      toast.success("Site deleted successfully");
      fetchSites(); // Refresh the sites
    } catch (err) {
      console.error("Failed to delete site:", err);
      toast.error("Failed to delete site. ", err.message);
    }
  };  
  
  return (
    <Layout>
      {isDeleteSiteModalOpen && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setIsDeleteSiteModalOpen(false)}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Delete Site Confirmation: {currentSiteToDelete.name}</p>
              <button className="delete" aria-label="close" onClick={() => setIsDeleteSiteModalOpen(false)}></button>
            </header>
            <section className="modal-card-body">
              <p>Are you sure you want to delete this site? This action cannot be undone.</p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-danger" onClick={handleDeleteSite}>Delete</button>
              <button className="button" onClick={() => setIsDeleteSiteModalOpen(false)}>Cancel</button>
            </footer>
          </div>
        </div>
      )}

      <div className="section">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h3 className="title is-4">Sites</h3>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-info" onClick={() => setIsAddModalActive(true)}>Add site</button>
              </div>
            </div>
          </div>
          <div className={`modal ${isAddModalActive ? "is-active" : ""}`}>
            <div className="modal-background" onClick={() => setIsAddModalActive(false)}></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Add New Site</p>
                <button className="delete" aria-label="close" onClick={() => setIsAddModalActive(false)}></button>
              </header>
              <section className="modal-card-body">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Site Name</label>
                    <div className="control">
                      <input className="input" type="text" name="name" value={newSite.name} onChange={handleSiteChange} required />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Address</label>
                    <div className="control">
                      <input className="input" type="text" name="jms_server_address" value={newSite.jms_server_address} onChange={handleSiteChange} required />
                      <p className="help">The address of the JMS server with protocol (e.g., https://jms.example.com)</p>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">JMS Server Port</label>
                    <div className="control">
                      <input className="input" type="number" name="jms_server_port" value={newSite.jms_server_port} onChange={handleSiteChange} required />
                    </div>
                  </div>

                  
                  <div className="box">
                    <h4 className="title is-6">Initial JMS Server Key</h4>
                    <div className="field">
                      <label className="label">Key Name</label>
                      <div className="control">
                        <input className="input" type="text" name="name" value={newKey.name} onChange={handleKeyChange} required />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Key Value</label>
                      <div className="control">
                        <input className="input" type="password" name="key" value={newKey.key} onChange={handleKeyChange} autoComplete="false" required />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input type="checkbox" name="isEnabled" checked={newKey.isEnabled} onChange={handleKeyChange} />
                          {" "}Enable this key
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="modal-card-foot">
                    <button type="submit" className="button is-success">Save Site</button>
                    <button type="button" className="button" onClick={() => setIsAddModalActive(false)}>Cancel</button>
                  </div>
                </form>
              </section>
            </div>
          </div>
          {isLoading ? <FullScreenLoader /> : error ? <p>{error}</p> : (
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Site name</th>
                  <th>JMS Server Address</th>
                  <th>Active Keys</th>
                  <th>JMS Keys</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sites.map(site => (
                  <tr key={site._id}>
                    <td><Link to={`/admin/site/${site._id}`}>{site.name}</Link></td>
                    <td>{site.jms_server_address}:{site.jms_server_port}</td>
                    <td>
                      {site.jms_server_keys && site.jms_server_keys.filter(k => k.isEnabled).length > 0 ? (
                        <span className="tag is-success">{site.jms_server_keys.filter(k => k.isEnabled).length} active</span>
                      ) : (
                        <span className="tag is-danger">No active keys</span>
                      )}
                    </td>
                    <td>
                      {site.jms_server_keys && site.jms_server_keys.length > 0 ? (
                        <span className="tag is-info">{site.jms_server_keys.length} key(s)</span>
                      ) : (
                        <span className="tag is-warning">No keys</span>
                      )}
                    </td>
                    <td>
                      <button className="button is-danger is-small is-outlined" onClick={() => handleDeleteSiteClick(site)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SitesPage;