import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MagnifyingGlass,
  ClockCounterClockwise,
  UserList,
  Users,
  Notepad,
  Buildings,
} from "phosphor-react";
import UseAuthStatus from "../Hooks/UseAuthStatus";
import LogoutButton from "./LogoutButton";

const Sidebar = () => {
  const { userEmail, userRole, tokenExp } = UseAuthStatus();
  const navigate = useNavigate();
  const [minutesRemaining, setMinutesRemaining] = useState(null);

  useEffect(() => {
    let interval;
    if (tokenExp) {
      // Immediately set the remaining minutes:
      const diff = tokenExp - Date.now();
      if (diff > 0) {
        setMinutesRemaining(Math.floor(diff / (60 * 1000)));
      }
      // Update every minute:
      interval = setInterval(() => {
        const now = Date.now();
        const diff = tokenExp - now;
        if (diff <= 0) {
          clearInterval(interval);
          // Session expired: redirect to login screen
          navigate("/login");
        } else {
          const minutes = Math.floor(diff / (60 * 1000));
          setMinutesRemaining(minutes);
        }
      }, 60000); // update every minute
    }
    return () => clearInterval(interval);
  }, [tokenExp, navigate]);

  return (
    <aside className="menu p-6">
      <Link to={"/search"}>
        <img
          src="/merlin-logo.png"
          style={{ height: "100px", width: "auto" }}
          alt="Merlin Entertainments"
        />
      </Link>

      {/* General Menu */}
      <p className="menu-label mt-6 is-size-6">General</p>
      <ul className="menu-list">
        <li>
          <a href="/search" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <MagnifyingGlass weight="duotone" className="has-text-info" />
              </span>
              <span>New search</span>
            </span>
          </a>
        </li>
        <li>
          <a href="/history" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <ClockCounterClockwise weight="duotone" className="has-text-info" />
              </span>
              <span>Search history</span>
            </span>
          </a>
        </li>
        <li>
          <a href="/activity" className="is-size-5">
            <span className="icon-text">
              <span className="icon">
                <UserList weight="duotone" className="has-text-info" />
              </span>
              <span>My activity</span>
            </span>
          </a>
        </li>
      </ul>

      {/* Admin Menu */}
      {userRole === "admin" && (
        <>
          <p className="menu-label mt-6 is-size-6">Administration</p>
          <ul className="menu-list">
            <li>
              <a href="/admin/users" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Users weight="regular" className="has-text-info" />
                  </span>
                  <span>Users</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/sites" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Buildings weight="regular" className="has-text-info" />
                  </span>
                  <span>Sites</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/logs" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Notepad weight="duotone" className="has-text-info" />
                  </span>
                  <span>Logs</span>
                </span>
              </a>
            </li>
            <li>
              <a href="/admin/jms-requests-stats" className="is-size-5">
                <span className="icon-text">
                  <span className="icon">
                    <Notepad weight="duotone" className="has-text-info" />
                  </span>
                  <span>JMS requests</span>
                </span>
              </a>
            </li>
          </ul>
        </>
      )}

      {/* User Info and Session Countdown */}
      <p className="menu-label mb-0 mt-6 is-size-7">{userEmail}</p>
      <p className="help">
        Your session will expire in:{" "}
        {minutesRemaining !== null ? `${minutesRemaining} minutes` : "Loading..."}
      </p>
      <ul className="menu-list mt-3">
        <li>
          <LogoutButton />
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
