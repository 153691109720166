import React from "react";

const SearchResults = ({ searchResponse = { tickets: [] }, navigate }) => {
  const { tickets: results } = searchResponse;

  console.log("tickets in searchResults", results);

  if (!results || results.length === 0) {
    return <p>No results found that match your search.</p>;
  }

  return (
    <>
      <p className="notification is-success has-text-weight-bold">
        Found {results.length} result{results.length > 1 ? "s" : ""}.
      </p>

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Site</th>
            <th>Ticket ID</th>
            <th>Plate</th>
            <th>Barcode</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td className="is-vcentered">
                {result.siteName || <em className="has-text-grey">Not set</em>}
              </td>
              <td style={{ height: "75px" }} className="is-vcentered">
                <a
                  href={`/ticket/${result.siteId}/${result.identifier}`}
                  className="has-text-weight-bold is-size-5"
                >
                  {result.identifier}
                </a>
              </td>
              <td className="is-vcentered">
                {result.medias &&
                result.medias.filter((media) => media.type === "plate").length > 0 ? (
                  result.medias
                    .filter((media) => media.type === "plate")
                    .map((media, mediaIndex) => (
                      <p key={mediaIndex}>
                        <span className="tag is-small is-warning plate-number">
                          {media.identifier}
                        </span>
                      </p>
                    ))
                ) : (
                  <em className="has-text-grey">Not set</em>
                )}
              </td>
              <td className="is-vcentered">
                {result.medias &&
                result.medias.filter((media) => media.type === "barcode").length > 0 ? (
                  result.medias
                    .filter((media) => media.type === "barcode")
                    .map((media, mediaIndex) => (
                      <p key={mediaIndex} className="barcode">
                        {media.identifier}
                      </p>
                    ))
                ) : (
                  <em className="has-text-grey">Not set</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SearchResults;
