// /helpers/dateHelpers.js

import { format } from 'timeago.js';

export function getDaysUntilValidityTo(validityTo){
  // Example usage
  // const daysUntil = getDaysUntilValidityTo(result.validityTo);

  const validityToDate = new Date(validityTo);
  // Create a Date object for today's date
  const today = new Date();
  // Set the time of today's date to 00:00:00 for accurate day difference calculation
  const todayDateOnly = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  // Calculate the difference in milliseconds
  const diffInMs = validityToDate - todayDateOnly;
  // Convert milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return Math.ceil(diffInDays); // Use Math.ceil to round up to the nearest whole number
};

export function formatDateWithOrdinal(date) {
  if (!date) return '';

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const ordinal = getOrdinal(day);

  // Format the date with ordinal, time, timezone, and time ago
  const formattedDate = `${day}${ordinal} ${dateObj.toLocaleDateString('en-US', { 
    month: 'short', 
    year: 'numeric' 
  })} at ${dateObj.toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  })} (${format(date)})`;

  return formattedDate;
}

function getOrdinal(n) {
  const remainder100 = n % 100;
  if (remainder100 >= 11 && remainder100 <= 13) {
    return "th";
  }
  const remainder10 = n % 10;
  switch (remainder10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
