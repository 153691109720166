import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import FullScreenLoader from "./FullScreenLoader/FullScreenLoader";
import { toast } from "react-toastify";
import { formatDateWithOrdinal } from "../Helpers/DateHelpers";

const SiteEditPage = () => {
  const { id } = useParams(); // To access the site ID from the URL
  const navigate = useNavigate();

  const [site, setSite] = useState({
    name: "",
    jms_server_address: "",
    jms_server_port: "",
    jms_server_keys: []
  });
  
  const [newKey, setNewKey] = useState({
    name: "",
    key: "",
    isEnabled: true
  });
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isAddKeyModalActive, setIsAddKeyModalActive] = useState(false);

  useEffect(() => {
    const fetchSite = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ADDRESS}/site/${id}`,
          {
            withCredentials: true
          }
        );
        setSite(response.data);
      } catch (err) {
        console.error("Failed to fetch site details:", err);
        setError("Failed to load site details. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSite();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSite((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  const handleKeyChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewKey((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddKey = () => {
    if (!newKey.name || !newKey.key) {
      toast.error("Key name and value are required.");
      return;
    }
    
    setSite((prevSite) => ({
      ...prevSite,
      jms_server_keys: [...prevSite.jms_server_keys, {
        ...newKey,
        createdAt: new Date(),
        updatedAt: new Date()
      }]
    }));
    
    setNewKey({
      name: "",
      key: "",
      isEnabled: true
    });
    
    setIsAddKeyModalActive(false);
  };

  const handleToggleKeyStatus = (index) => {
    setSite((prevSite) => {
      const updatedKeys = [...prevSite.jms_server_keys];
      updatedKeys[index] = {
        ...updatedKeys[index],
        isEnabled: !updatedKeys[index].isEnabled,
        updatedAt: new Date()
      };
      
      return {
        ...prevSite,
        jms_server_keys: updatedKeys
      };
    });
  };

  const handleRemoveKey = (index) => {
    setSite((prevSite) => {
      const updatedKeys = [...prevSite.jms_server_keys];
      updatedKeys.splice(index, 1);
      
      return {
        ...prevSite,
        jms_server_keys: updatedKeys
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`${process.env.REACT_APP_API_ADDRESS}/sites/${id}`, site, {
        withCredentials: true
      });
      toast.success("Site updated successfully");
      navigate("/admin/sites"); // Redirect to the sites list page after successful update
    } catch (err) {
      console.error("Failed to update site:", err);
      setError("Failed to update site. Please try again.");
      toast.error("Failed to update site. ", err.message);
    }
  };

  return (
    <Layout>
      <div className="section">
        <div className="container">
          {isLoading ? (
            <FullScreenLoader />
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="columns">
              <div className="column is-12">
                <form onSubmit={handleSubmit}>
                  <h3 className="title is-4">Editing site: {site.name}</h3>
                  <div className="columns">
                    <div className="column is-5">
                      <div className="field">
                        <label className="label">Site Name</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            value={site.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">JMS Server Address</label>
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="jms_server_address"
                            value={site.jms_server_address}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label">JMS Server Port</label>
                        <div className="control">
                          <input
                            className="input"
                            type="number"
                            name="jms_server_port"
                            value={site.jms_server_port}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="control">
                        <button type="submit" className="button is-info mr-2">
                          Update Site
                        </button>
                        <button type="button" className="button is-primary" onClick={() => setIsAddKeyModalActive(true)}>
                          Add New Key
                        </button>
                      </div>
                    </div>
                    
                    <div className="column is-7">
                      <h4 className="title is-5">JMS Server Keys</h4>
                      {site.jms_server_keys && site.jms_server_keys.length > 0 ? (
                        <table className="table is-fullwidth is-hoverable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Status</th>
                              <th>Created</th>
                              <th>Last Updated</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {site.jms_server_keys.map((keyItem, index) => (
                              <tr key={index}>
                                <td>{keyItem.name}</td>
                                <td>
                                  <span className={`tag ${keyItem.isEnabled ? 'is-success' : 'is-warning'}`}>
                                    {keyItem.isEnabled ? 'Active' : 'Disabled'}
                                  </span>
                                </td>
                                <td>{formatDateWithOrdinal(keyItem.createdAt)}</td>
                                <td>{formatDateWithOrdinal(keyItem.updatedAt)}</td>
                                <td>
                                  <div className="buttons">
                                    <button 
                                      type="button"
                                      className={`button is-small ${keyItem.isEnabled ? 'is-warning' : 'is-success'}`}
                                      onClick={() => handleToggleKeyStatus(index)}
                                    >
                                      {keyItem.isEnabled ? 'Disable' : 'Enable'}
                                    </button>
                                    <button 
                                      type="button"
                                      className="button is-small is-danger"
                                      onClick={() => handleRemoveKey(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="notification is-warning">
                          No keys have been added yet. Click "Add New Key" to add your first key.
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add Key Modal */}
      <div className={`modal ${isAddKeyModalActive ? "is-active" : ""}`}>
        <div className="modal-background" onClick={() => setIsAddKeyModalActive(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add New Key</p>
            <button className="delete" aria-label="close" onClick={() => setIsAddKeyModalActive(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Key Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={newKey.name}
                  onChange={handleKeyChange}
                  placeholder="E.g., Primary Key, Backup Key"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Key Value</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  name="key"
                  value={newKey.key}
                  onChange={handleKeyChange}
                  autoComplete="new-password"
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="isEnabled"
                    checked={newKey.isEnabled}
                    onChange={handleKeyChange}
                  />
                  {" "}Enable this key
                </label>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={handleAddKey}>Add Key</button>
            <button className="button" onClick={() => setIsAddKeyModalActive(false)}>Cancel</button>
          </footer>
        </div>
      </div>
    </Layout>
  );
};

export default SiteEditPage;